import {getBase64} from "@/utils/common";
import jsPDF from "jspdf";

export default {
    data(){
        return{
        }
    },
    methods:{
        handleStatusClass(val){
            let statusName, className;
            switch (val) {
                case 1060 : //'使用中'
                    className = "using";
                    statusName = "使用中";
                    break;
                case 1061 :  //停用中(医院)'
                    className = "stopHospital";
                    statusName = "停用中(医院)";
                    break
                case 1062 : //'停用中(经销商)'
                    className = "stopCustorm";
                    statusName = "停用中(经销商)";
                    break
                case 1063: // '返回厂内'
                    className = "returnInFactory";
                    statusName = "返回厂内";
                    break
            }
            return {
                statusName: statusName,
                className: className
            };
        },
        handleStatus(val){
            let statusName, className;
            switch (val) {
                case '使用中':
                    // statusName = "使用中";
                    className = "inUse";
                    break;
                case '待出库':
                    // statusName = "待出库";
                    className = "waitOutStore";
                    break;
                case '待发货':
                    // statusName = "待发货";
                    className = "waitDelivery";
                    break;
                case '已发货':
                    // statusName = "已发货";
                    className = "alreadyDelivery";
                    break;
                case '待装机':
                    // statusName = "待装机";
                    className = "waitInstalled";
                    break;
                case '待返厂':
                    // statusName = "待返厂";
                    className = "waitReturnFactory";
                    break;
                case '返厂中':
                    // statusName = "返厂中";
                    className = "ReturningFactory";
                    break;
                // case '报废中':
                //   // statusName = "报废中";
                //   className = "waitDelivery";
                //   break;
                case '已报废':
                    // statusName = "已报废";
                    className = "alreadyStop";
                    break;
                case '已停产':
                    // statusName = "已停产";
                    className = "alreadyScrap";
                    break;
                case '维修中':
                    // statusName = "维修中";
                    className = "alreadyMaintance";
                    break;
                case '已维修':
                    // statusName = "已维修";
                    className = "alreadyMaintance";
                    break;
                case '出场中':
                    // statusName = "出场中";
                    className = "outStore";
                    break;
                default:
                    statusName = "Unknown";
                    className = "unknown-status";
            }
            return {
                // statusName: statusName,
                className: className
            };
        },
        // 下载文件
        downloadFile(url) {
            return new Promise((resolve, reject) => {
                // const fileName = url
                let link = document.createElement('a')
                link.innerText = "下载"
                // link.style.display = 'none'
                link.setAttribute('href', url)
                link.setAttribute('download', "文件.pdf")
                document.body.appendChild(link);
                // 这个延迟操作的目的是为了确保浏览器有足够的时间来处理下载操作
                // 这样可以避免在循环中快速触发多个下载操作，导致浏览器无法正确处理下载请求
                link.addEventListener('click', () => {
                    setTimeout(() => {
                        document.body.removeChild(link);
                        resolve()
                    }, 1000)
                })
                link.click()
            })
        },
        downloadPdf(url) {  //pdf文件下载
            // 通过url获取文件名
            const fileName = url.substring(url.lastIndexOf('/') + 1);
            // 将文件通过xhr进行异步下载，然再通过读取文件流blob对象，再触发按钮下载
            // 最终效果 href = ""
            this.fileLinkToStreamDownload(url, fileName, 'pdf')
        },
        fileLinkToStreamDownload(url, fileName, type){
            let xhr = new XMLHttpRequest();
            xhr.open('get', url, true);
            xhr.setRequestHeader('Content-Type', `application/${type}`);
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (this.status === 200) {
                    // 接受二进制文件流
                    let blob = this.response;
                    // blob:http://localhost:8080/f843aaaa-d742-4c62-b583-b44d2af70c5b
                    const blobUrl = window.URL.createObjectURL(blob);
                    // 这里的文件名根据实际情况从响应头或者url里获取
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    // 为了节省内存和避免泄露，应该调用 revokeObjectURL 来释放与之关联的资源
                    window.URL.revokeObjectURL(blobUrl);
                }
            }
            xhr.send();
        }
    }
}